export const JAM_ENTER         = 'JAM_ENTER';
export const JAM_LEAVE         = 'JAM_LEAVE';

export const enter = (jamId) => (dispatch) => {
  dispatch({
    type: JAM_ENTER,
    jamId,
  });
};

export const leave = (jamId) => (dispatch) => {
  dispatch({
    type: JAM_LEAVE,
    jamId,
  });
};
