import api from '../api';

import { importFetchedStatuses } from './importer';

export const PINNED_GLOBAL_STATUSES_FETCH_REQUEST = 'PINNED_STATUSES_FETCH_REQUEST';
export const PINNED_GLOBAL_STATUSES_FETCH_SUCCESS = 'PINNED_STATUSES_FETCH_SUCCESS';
export const PINNED_GLOBAL_STATUSES_FETCH_FAIL = 'PINNED_STATUSES_FETCH_FAIL';

export function fetchPinnedGlobalStatuses() {
  return (dispatch, getState) => {
    dispatch(fetchPinnedGlobalStatusesRequest());

    api(getState).get(`/api/v1/admin/statuses/pinned`).then(response => {
      dispatch(importFetchedStatuses(response.data));
      dispatch(fetchPinnedGlobalStatusesSuccess(response.data, null));
    }).catch(error => {
      dispatch(fetchPinnedGlobalStatusesFail(error));
    });
  };
}

export function fetchPinnedGlobalStatusesRequest() {
  return {
    type: PINNED_GLOBAL_STATUSES_FETCH_REQUEST,
  };
}

export function fetchPinnedGlobalStatusesSuccess(statuses, next) {
  return {
    type: PINNED_GLOBAL_STATUSES_FETCH_SUCCESS,
    statuses,
    next,
  };
}

export function fetchPinnedGlobalStatusesFail(error) {
  return {
    type: PINNED_GLOBAL_STATUSES_FETCH_FAIL,
    error,
  };
}
