import {connect} from 'react-redux';

import {createJam} from "jam-core";

import { enter, leave } from '../actions/jams';
import Jam from '../components/jam';
import { me } from '../initial_state';


let _jamInstance = null;
let _jamHost = null;
let _roomId = null;

const createJamInstance = (jamHost, jamProxyBaseUrl, jamConfig, roomId) => {


  if(jamHost !== _jamHost || _roomId !== roomId || !_jamInstance) {
    _jamHost = jamHost;
    _roomId = roomId;
    _jamInstance = createJam({
      jamConfig: {
        ...jamConfig,
        urls: {
          ...jamConfig.urls,
          pantry: `${jamProxyBaseUrl}/jam-proxy/${jamHost}/_/pantry`,
        },
      },
      initialProps: {
        roomId,
        autoJoin: false
      },
      debug: false,
    });
  }
  return _jamInstance;
}

const getRoomInfo = async (jamHost, jamProxyBaseUrl, roomId) => {

  const result = await  fetch(`${jamProxyBaseUrl}/jam-proxy/${jamHost}/_/pantry/api/v1/rooms/${roomId}`);

  return result.json();
}


const mapDispatchToProps = (dispatch, { jamId }) => ({
  enterJam: () => {
    dispatch(enter(jamId));
  },
  leaveJam: () => {
    dispatch(leave(jamId));
  },
});

const mapStateToProps = (state, { jamId }) => {

  const jam = state.getIn(['jams', jamId]);
  const roomId = jam.get('room_id');

  const jamHost = jam.get('jam_host');

  const jamProxyBaseUrl = state.getIn(['meta', 'jam_proxy_base_url']);
  const jamConfig = state.getIn(['meta', 'jam_config']).toJS();

  return {
    jam,
    account: state.getIn(['accounts', me]),
    createJamInstance: () => createJamInstance(jamHost, jamProxyBaseUrl, jamConfig, roomId),
    getRoomInfo: () => getRoomInfo(jamHost, jamProxyBaseUrl, roomId),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Jam);
