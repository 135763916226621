import PropTypes from 'prop-types';
import React from 'react';

import ImmutablePropTypes from 'react-immutable-proptypes';

import {JamProvider} from 'jam-core-react';
import { DateTime } from 'luxon';

import { Avatar } from 'mastodon/components/avatar';

import JamRoom from './jam_room';


const PreSchedInfo = ({ schedule }) => {
  // add countdown ting

  let scheduleToLocal = DateTime.fromISO(`${schedule.get('date')}T${schedule.get('time') ? `${schedule.get('time')}:00` : '00:00:00'}`, { zone: schedule.get('timezone') })
    .setZone(Intl.DateTimeFormat().resolvedOptions().timeZone).toString();

  return (
    <div style={{ alignSelf: 'center' }}>
      🗓 scheduled for {scheduleToLocal.slice(0, 10)} at {scheduleToLocal.slice(11, 16)} <br />
      UTC{scheduleToLocal.slice(23, 29)}
    </div>
  )
}
PreSchedInfo.propTypes = {
  schedule: ImmutablePropTypes.map,
}

function ClosedInfo() {
  return (
    <div style={{ alignSelf: 'center' }}>
      This room is closed at the moment and only accessible for moderators.
    </div>
  )
}

const JamLobby = ({jam, roomState, iAmModerator, loggedIn, enterRoom}) => {

  const room_config = jam.get('room_config');
  const speakers = jam.get('speakers');


  let sched = null;
  let invalidsched = false;

  if (room_config) {
    sched = room_config.get('schedule');
    if (sched && (typeof sched !== 'string') && sched.get('date')) {

      let now = DateTime.now();
      let scheduledAt = DateTime.fromISO(`${sched.get('date')}T${sched.get('time') ? `${sched.get('time')}:00` : '00:00:00'}`, { zone: sched.get('timezone') })

      invalidsched = now < scheduledAt;
    }
  }

  const roomClosed = roomState?.closed;

  const canJoin = loggedIn && (iAmModerator || (!invalidsched && !roomClosed));

  return (
    <div>
      <div className={'jam-room-outside'}>
        <ul>
          {speakers.map((speaker) => (
            <li key={speaker.get('acct')}>
              <div><Avatar account={speaker} size={48} /></div>
            </li>
          ))}
        </ul>
      </div>
      <div className='jam-action-bar'>
        { invalidsched && <PreSchedInfo schedule={sched} /> }
        { roomClosed && <ClosedInfo /> }
        { canJoin && <button className={'button'} onClick={enterRoom}>Join Jam</button> }
      </div>
    </div>
  );
}
JamLobby.propTypes = {
  jam: ImmutablePropTypes.map,
  roomState: PropTypes.any,
  iAmModerator: PropTypes.bool,
  loggedIn: PropTypes.bool,
  enterRoom: PropTypes.func,
}


export default class Jam extends React.PureComponent {

  static propTypes = {
    jam: ImmutablePropTypes.map,
    account: ImmutablePropTypes.map,
    enterJam: PropTypes.func,
    leaveJam: PropTypes.func,
    deployFloatingJam: PropTypes.func,
    createJamInstance: PropTypes.func,
    getRoomInfo: PropTypes.func,
  };

  static defaultProps = {};

  componentWillUnmount() {
    if (this.props.jam.get('entered') && this.props.deployFloatingJam) {
      this.props.deployFloatingJam(this.props.jam);
    }
  }

  componentDidMount() {
    const {account, getRoomInfo} = this.props;

    const setupJamRoom = async () => {
      const jamId = account.toJSON().jam_identity;
      const roomState = await getRoomInfo();

      const iAmModerator = roomState.moderators.includes(jamId);
      this.setState({ iAmModerator, roomState });
    };

    setupJamRoom();
  }

  enterRoom = () => {
    const { enterJam } = this.props;
    enterJam();
  }

  leaveRoom = () => {
    const { leaveJam } = this.props;
    leaveJam();
  }

  renderJamRoom = (jam, account) => {
    return (
      <JamRoom roomId={jam.get('room_id')} handleleaveRoom={this.leaveRoom} jam={jam} account={account} />
    );
  }

  render() {

    const { jam, account } = this.props;

    const loggedIn = !!account;

    const {roomState, iAmModerator} = this.state || {};

    return jam.get('entered') ? (() => {
        const [state, api] = this.props.createJamInstance();
        return (
            <JamProvider state={state} api={api}>
              <JamRoom roomId={jam.get('room_id')} handleleaveRoom={this.leaveRoom} jam={jam} account={account} />
            </JamProvider>
          );
        })()
        :
        <JamLobby jam={jam} roomState={roomState} iAmModerator={iAmModerator} loggedIn={loggedIn} enterRoom={() => this.enterRoom()} />;
  };

}
